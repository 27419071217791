





































import Vue from "vue";
import {Image} from "@/store/types";

export default Vue.extend({
  name: "CollTable",

  props: {
    items: { type: Array, default: null },
    loading: { type: Boolean, default: false },
    total: { type: Number, default: 0 }
  },

  data: () => ({
    options: {},
    page: 1,
    pageSize: 10,
    headers: [
      {
        text: "Imagem",
        value: "url",
        sortable: false,
        width: "100"
      },
      {
        text: "Dimensão",
        align: "center",
        value: "dimension",
        sortable: false,
        width: "120"
      },
      {
        text: "Tags",
        value: "tags",
        sortable: false,
      },
      {
        text: "Status",
        value: "info",
        align: "end",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        width: "110",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  watch: {
    options: {
      handler (options) {
        this.fetch(options.page, options.itemsPerPage);
      },
      deep: true,
    },
  },

  methods: {
    fetch(page: number, pageSize: number): void {
      if (page === this.page && pageSize === this.pageSize) return;
      this.page = page;
      this.pageSize = pageSize;

      // FIXME o backend começa a paginação em 0, resolver isso depois
      this.$emit("fetch", { page: page - 1, pageSize })
    },

    onEdit(item: Image): void {
      this.$emit("onEdit", item)
    },

    onUpdate(item: Image): void {
      this.$emit("onUpdate", item)
    },
  }
})


































import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import CollForm from "./components/CollForm.vue";
import CollTable from "./components/CollTable.vue";
import {Image} from "@/store/types";

export default Vue.extend({
  name: "ImagesPage",

  components: {
    FullScreenDialog,
    CollForm,
    CollTable
  },

  data: () => ({
    fullScreenDialog: false,
    image: null as unknown | Image,
  }),

  computed: {
    ...mapGetters({
      images: "imagesModule/getImages",
      total: "imagesModule/getTotal",
      loading: "imagesModule/getLoading"
    })
  },

  created() {
    this.$store.dispatch("imagesModule/fetch");
  },

  methods: {
    editCol(image: Image): void {
      this.image = image;
      this.openFullScreenDialog();
    },

    onUpdate(image: Image): void {
      this.$store.dispatch("imagesModule/update", image);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    fetch(payload: { page: number, pageSize: number }): void {
      this.$store.dispatch("imagesModule/fetch", payload);
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.image = null;
    },
  }
})

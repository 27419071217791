





































import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import { getImageDimensions } from "@/utils";
import {Image, ImageUpload} from "@/store/types";

export default Vue.extend({
  name: "CollForm",

  props: {
    image: { type: Object, default: null }
  },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      payload: {
        _id: "",
        url: "",
        width: 0,
        height: 0,
        tags: [],
        active: true,
      } as Image
    };
  },

  mounted() {
    if (this.image) {
      Object.assign(this.payload, this.image)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;

      if (this.image) {
        this.payload.updated_at = new Date()
        this.$store.dispatch("imagesModule/update", this.payload);
      } else {
        this.$store.dispatch("imagesModule/create", this.payload);
      }

      this.$emit("close");
    },

    validateForm(): Boolean {
      // if (!this.payload.sku) {
      //   this.showError("Insira o SKU da oferta!");
      //   return false;
      // }
      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    },

    getFile(file: File) {
      if (!file.type.includes("image")) {
        return this.$store.dispatch("app/showCustomError", "Arquivo não Suportado!");
      }

      const extension = file.name.split(".")[1];

      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const image: ImageUpload = {
          folder: "/images/banners",
          filename: `${uuidv4()}.${extension}`,
          file: new Uint8Array(target?.result as ArrayBuffer),
          metadata: {
            contentType: file.type,
            size: file.size,
          }
        }

        const dimensions = await getImageDimensions(file);

        this.$store.dispatch("uploader/upload", image)
            .then(url => {
              this.payload.url = url
              this.payload.height = dimensions.height;
              this.payload.width = dimensions.width;
            })
      };
      reader.readAsArrayBuffer(file);
    }
  }
});
